import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

import "../styles/style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
// You can delete this file if you're not using it
const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Accelerate" keywords={[]} />
    <Row style={{minHeight: '400px'}} className="home">
      <Col lg={7} sm={12} className="dark d-flex align-content-around flex-wrap" >
        <div className="hero">
          <h1 className="partner">Partner <span className="lead">With Accelerate</span></h1>
        </div>
      </Col>
      <Col lg={5} sm={12} className="light d-flex align-content-around flex-wrap" >
        <div className="hero-txt">
          <p className="icon">
            <Link to="/about">
              <img src="/images/controller.svg" alt="Accelerate controller"/>
              We partner with studios.
            </Link>
          </p>
          <p className="icon">
            <Link to="/team">
              <img src="/images/team.svg" alt="Accelerate team" className="icon"/>
              Our team is our value.
            </Link>
          </p>
          <p className="icon">
            <Link to="/pitch">
              <img src="/images/pitch.svg" alt="Accelerate pitch" className="icon"/>
              Pitch your game.
            </Link>
          </p>
        </div>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
